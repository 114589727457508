import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/browser';
// import reportWebVitals from './reportWebVitals';

if (
  import.meta.env.NODE_ENV === 'production' &&
  import.meta.env.VITE_SENTRY_DSN &&
  import.meta.env.VITE_SENTRY_RELEASE
) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_SENTRY_RELEASE
  });
}

const root = createRoot(document.getElementById('root'));

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
